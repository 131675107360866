<template>
    <div id="bg">
        <div id="login">
            <el-card class="card_title">
                <span style="font-size: 20px;line-height: 20px;  display: flex;justify-content: center;">账户注册</span>
                <div id="form">
                    <el-input v-model="ruleForm.username"  prop="email" prefix-icon='el-icon-user-solid'placeholder="请输入账户名"></el-input>
                    <el-input type="password" placeholder="请输入密码" prefix-icon='el-icon-lock' v-model="ruleForm.passWord" autocomplete="off"></el-input>
<!--                    <el-input type="password" placeholder="确认密码" prefix-icon='el-icon-lock' v-model="ruleForm.checkPass" autocomplete="off" ></el-input>-->
                    <a href="/#/login/index" class="forge-register" style="float: right;margin-right: 20px" >使用已有账户登录</a>
                    <el-button type="primary" class="login-button" @click="submitForm" >确定</el-button>
                    <div class="home_form_footer">
                        <el-checkbox v-model="checked" checked-color="#ff3430">
                            <p class="agreement">
                                我已阅读并接受
                                <span @click="showAgreement" >《隐私政策》</span>
                            </p>
                        </el-checkbox>
                    </div>
                </div>
            </el-card>
        </div>

    </div>

</template>

<script>
    import { get, post } from "@/utils/request";

    export default {
        name: "index",
        data(){
            return {
                ruleForm:{},
                checked:false,
                visible:false,
            }
        },
        methods: {
            validateEmail(email) {
                const regex = /^[1][3,4,5,7,8][0-9]{9}$/;
                return regex.test(email);
            },
            showAgreement () {
                window.location.href="http://jolo.play920.com/registProtocol.html"
            },
            submitForm(){
                var isStart = true;
                var data = this.ruleForm;
                if (!data.username){
                    this.$message.warning("账户名不能为空！");
                    isStart =false;
                }else if (!data.passWord){
                    this.$message.warning("账户密码不能为空！");
                    isStart= false;
                }else if (!this.validateEmail(data.username)){
                    this.$message.warning("账户名请输入正确手机号码格式！");
                    isStart = false;
                }else if (!this.checked){
                    this.$message.warning("请阅读并勾选隐私政策");
                    isStart = false;
                }
                if (isStart) {
                    this.$http.post("https://api.xinyuanhudong.com/system/usr/register",{'userName':this.ruleForm.username,"password":this.ruleForm.passWord}).then(res =>{
                        if (res.body.code === 200){
                            this.$message.success(res.body.msg);
                            this.$router.push({
                                path: "/login/index",
                            })
                        }else {
                            this.$message.error(res.body.msg)
                        }
                    })




                }
            },
        }
    }
</script>

<style lang="scss">
    #bg {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        /* 渐变 */
        background-size: 400%;
        background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;

    }
    .forge-register {
        font-size: 14px;
        color: dodgerblue;
        margin-top: -4px;
    }
    .login-button {
        padding: 0 15px;
        font-size: 16px;
        height: 40px;
        width: 100%;
    }
    #logo {
        color: aliceblue;
        font-size: 25px;
        font-weight: 800;
        text-transform: uppercase;
        position: absolute;
        left: 15%;
        top: 15%;
    }
    #login {
        display: flex;
        align-items: center;
        .card_title{
            margin: auto;
            width: 80%;
            margin-top: 45%;
        }
    }
    .el-card {
        border-radius: 3%;
        width: 373px;
        /*height: 85%;*/
        background-color: rgba(255, 255, 255, 0.35);
        border: 0px;
    }
    #form {
        padding-bottom: 15px;
        line-height: 50px;
    }
    #clock{
        color: aliceblue;
        position:absolute;
        top: 70%;
        left: 15%;
        text-align: left;
    }
    #time{
        font-size: 100px;
    }
    #date{font-size: 35px;}
    #copy{
        color:aliceblue;
        position:absolute;
        bottom: 5%;
        left: 0;
        right: 0;

    }
    .agreement {
        color: #999;
        font-size: 12px;
        font-weight: 500;
        span{
            color: #3060db;
        }
    }
</style>